import React from 'react';

import SEO from '../components/SEO';
import ProcessHorizontal from '../components/ProcessHorizontal';

//

const Play = () => (
  <>
    <SEO />
    <ProcessHorizontal />
  </>
);

export default Play;
